import React, { Component } from 'react';
// import Loading from '../components/LoadingPage';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../static/css/SignUp.css';
import logo from '../static/images/images/a_logo.jpg';
import stripe from '../static/images/images/stripe.svg';
class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            monthly: true,
            six_months: false,
            yearly: false,
            monthly_checked: true,
            six_months_checked: false,
            yearly_checked: false,
            payment: null
        };
        this.onChangeMonthly = this.onChangeMonthly.bind(this);
        this.onChangeSixMonths= this.onChangeSixMonths.bind(this);
        this.onChangeYearly = this.onChangeYearly.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    onChangeMonthly() {
        this.setState({ monthly: true, six_months: false, yearly: false, monthly_checked: true, six_months_checked: false, yearly_checked: false });
    }
    onChangeSixMonths() {
        this.setState({ monthly: false,  six_months: true, yearly: false, monthly_checked: false, six_months_checked: true, yearly_checked: false });
    }
    onChangeYearly() {
        this.setState({ monthly: false, six_months: false, yearly: true, monthly_checked: false, six_months_checked: false, yearly_checked: true });
    }
    async onSubmit(event) {
        event.preventDefault();
        // this.setState({ isLoading: true, })      
        const plan = {
            monthly: this.state.monthly,
            six_months: this.state.six_months,
            yearly: this.state.yearly,
            customer_id: this.state.customer_id
        };
        await axios.post(process.env.REACT_APP_SERVER_API + '/auth/pre_signup_plan', plan)
            .then(response => {  window.location.href = response.data.url; })
            .catch(err => { console.log(err) });
    };
    render() {
        return (
            <div className="signup-container"> 
                <div className="animates-logo-container"><Link to={'/coming-soon'}><img className="animates-logo hover" src={logo} alt="" /></Link></div>               
                <div className="signup-box">
                    <h2 className="color-1" style={{marginBottom: "-10px"}}><u>EXCLUSIVE LIMITED TIME OFFER</u></h2>
                    <p>Sign up today and lock in on our discounted pricing FOREVER! Prices will not change or expire for as long as you stay subscribed! <span role="img" aria-label="">😎</span></p><br />
                    <h2 className="color-2" style={{marginBottom: "-10px"}}><u>Member Features:</u></h2>
                    <ul style={{paddingInlineStart: "0px"}}>
                        <li style={{listStyle:"none"}}>- Meets. Dates. Anime. <span role="img" aria-label="">💓</span></li>
                        <li style={{listStyle:"none"}}>- Unlimited Likes and Chats! <span role="img" aria-label="">👍 ↔ 💬</span></li>
                        <li style={{listStyle:"none"}}>- Unlimited Rewinds! <span role="img" aria-label="">🔄</span></li>
                        <li style={{listStyle:"none"}}>- Premium preferences & filters! <span role="img" aria-label="">🧑‍💻👩‍💻</span></li>
                        <li style={{listStyle:"none"}}>- Prioritized Likes/Matches! <span role="img" aria-label="">🪪</span></li>
                        <li style={{listStyle:"none"}}>- Special Events! <span role="img" aria-label="">🗓️</span></li>
                        <li style={{listStyle:"none"}}>- And More! <span role="img" aria-label="">🔥</span></li>
                    </ul><br/>
                    <h2 className="color-1" style={{marginBottom: "-10px"}}><u>Process:</u></h2>
                    <ul style={{paddingInlineStart: "0px"}}>
                        <li style={{listStyle:"none"}}>1. SELECT YOUR PLAN. <span role="img" aria-label="">🚀</span></li>
                        <li style={{listStyle:"none"}}>2. ADD YOUR PAYMENT METHOD. <span role="img" aria-label="">🚀</span></li>
                        <li style={{listStyle:"none"}}>3. CREATE YOUR PROFILE. <span role="img" aria-label="">🚀</span></li>
                        <li style={{listStyle:"none"}}>4. MARK YOUR CALENDAR! <span role="img" aria-label="">🚀</span></li>
                    </ul><br/> 
                    <h2 className="color-2" style={{marginBottom: "20px"}}><u>Select Plan</u></h2>
                    <p style={{marginBottom: "0px", fontSize: "10px"}}>(You can cancel anytime.)</p>
                    <p><input className="sign-up-membership-checkbox" type="checkbox" checked={this.state.monthly_checked} onChange={this.onChangeMonthly} /> 1 Month <strike>$20</strike> $18/ Per Month </p>
                    <p><input className="sign-up-membership-checkbox" type="checkbox" checked={this.state.six_months_checked} onChange={this.onChangeSixMonths}/> 6 Months <strike>$15</strike> $12/ Per Month</p>
                    <p style={{marginBottom: "30px"}}><input className="sign-up-membership-checkbox" type="checkbox" checked={this.state.yearly_checked} onChange={this.onChangeYearly} /> 12 Month <strike>$10</strike> $8/ Per Month</p>   
                    <p style={{width: "95%", margin: "30px auto", marginBottom: "20px", fontSize: "10px"}}><u>PLEASE NOTE:</u> We require a valid payment method before the use of the platform to help avoid bots, spammers and fake accounts, you will not be charged today. All subscriptions and payments are handled through our trusted partner <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer"><img style={{height: "25px", marginBottom: "-9px"}} src={stripe} alt="Stripe"/></a>, we do not store any payment information. </p>
                    <p style={{marginBottom: "0px", fontSize: "10px"}}>(You will not be charged today, membership will begin on February 6, 2024, cancel anytime!)</p>            
                    <form onSubmit={this.onSubmit}><button className="signup-with-email">Continue</button></form>
                    <p className="signup-box-signup">
                        By continuing, you agree to AniMates's <Link to="/terms-of-service" className="no-dec"><strong>Terms Of Service</strong></Link>. We Will manage information about you as described in our <Link to="/privacy" className="no-dec"><strong>Privacy Policy</strong></Link>, and <Link to="/cookies" className="no-dec"><strong>Cookie Policy</strong></Link>.
                    </p>
                </div>
                <p className="welcomecs-footer-bottom-title" style={{fontSize: "10px"}}>2024 AniMates LLC</p>
            </div>
        );
    };
};
export default SignUp;