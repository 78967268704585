import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../static/css/SignUpConfirmation.css';
import Instagram from '../static/images/images/instagram.svg';
import Facebook from '../static/images/images/facebook.svg';
import Twitter from '../static/images/images/square-x-twitter-b.svg';
import Pinterest from '../static/images/images/pinterest.svg';
import logo from '../static/images/images/a_logo.jpg';
class SignUpConfirmation extends Component {
    constructor() {
        super();
        this.state = {};
    };
    render() {
        return (
            <div className="signup-confirmation-container">
                <div className="animates-logo-container"><img className="animates-logo" src={logo} alt="" /></div>               
                <p className="signup-confirmation-message">
                    Thank you and welcome to AniMates! <span role="img" aria-label="">🎊 🥳 🎉</span> 
                    <br /><br />
                    We are happy to have as a part of our community and look forward helping you and the thousands of anime fans find a match! <span role="img" aria-label="">❤️</span> 
                    <br /><br />
                    Mark your calendar! <span role="img" aria-label="">🗓️</span> We go live on February 6, 2024. <span role="img" aria-label="">🚀</span> You're subscription will become active then. Keep an eye out for updates and our news letters <span role="img" aria-label="">📰</span> through email <span role="img" aria-label="">💌 </span> and socials! 
                    <br /><br />
                    Until then, start giving a thought into your anime watch list to bring to the platform and find your matches! <span role="img" aria-label="">🫡💕 </span> 
                </p>
                <Link to="/" className="signup-confirmation-continue no-dec"><button className="signup-confirmation-continue">Exit</button></Link>
                <div className="signup-confirmation-footer-follow-soon-icons-container">
                    <h4 className="signup-confirmation-footer-follow-soon-icons-title color-2">GIVE US A FOLLOW</h4><br />
                    <div className="signup-confirmation-footer-follow-soon-icons">
                        <a className="signup-confirmation-footer-follow-soon-icon" href="https://www.instagram.com/animates_app/" target="_blank" rel="noopener noreferrer"><img className="signup-confirmation-footer-follow-social-icon" src={Instagram} alt="animates's Instagram link for anime enthusists looking for dates"/></a> 
                        <a className="signup-confirmation-footer-follow-soon-icon" href="https://www.facebook.com/AniMatesApp/" target="_blank" rel="noopener noreferrer"><img className="signup-confirmation-footer-follow-social-icon" src={Facebook} alt="animates's Facebook page link for anime enthusists looking for dates"/></a> 
                        <a className="signup-confirmation-footer-follow-soon-icon" href="https://twitter.com/AniMatesApp" target="_blank" rel="noopener noreferrer"><img className="signup-confirmation-footer-follow-social-icon" src={Twitter} alt="animates's Twitter link for anime enthusists looking for dates"/></a> 
                        <a className="signup-confirmation-footer-follow-soon-icon" href="https://www.pinterest.com/AniMatesApp" target="_blank" rel="noopener noreferrer"><img className="signup-confirmation-footer-follow-social-icon" src={Pinterest} alt="animates's Pinterests profile link for anime enthusists looking for dates"/></a> 
                    </div>
                </div>
                    <p className="signup-confirmation-footer" style={{fontSize: "10px", textAlign: "center"}}>2024 AniMates LLC</p>
            </div>
        );
    };
};
export default SignUpConfirmation;